

// Vendor scss
import '../sass/vendor.scss';

// jQuery

import 'jquery/dist/jquery.min';
// date picker
// import 'datepicker-full/datepicker-full.min'


// jQuery Validate
import 'jquery-validation/dist/jquery.validate.min';
import 'jquery-validation/dist/additional-methods.min';

// Bootstrap JS
import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle';

const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl)
})

const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})

// Feather icons JS
import './feather-icons';

// Slick JS
import 'slick-carousel/slick/slick.min';

// Fontawesome JS
import '@fortawesome/fontawesome-free/js/all';

// ECMAScript 2015
import "regenerator-runtime/runtime.js";

import jwt_decode from "jwt-decode";
